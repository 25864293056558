<template>
  <el-table
      :data="data"
      :style="style"
      :row-key="rowKey"
      :default-expand-all="defaultExpandAll"
      :max-height="maxHeight"
      :height="setHeight"
      :stripe="stripe"
      :border="true"
      :size="size"
      :fit="fit"
      ref="lidaTable"
      :show-header="showHeader"
      :highlight-current-row="highlightCurrentRow"
      :current-row-key="currentRowKey"
      :row-class-name="rowClassName"
      :row-style="rowStyle"
      :cell-class-name="cellClassName"
      :cell-style="cellStyle"
      :header-row-class-name="headerRowClassName"
      :header-row-style="headerRowStyle"
      :header-cell-class-name="headerCellClassName"
      :header-cell-style="headerCellStyle"
      :empty-text="emptyText"
      :expand-row-keys="expandRowKeys"
      :default-sort="defaultSort"
      :tooltip-effect="tooltipEffect"
      :show-summary="showSummary"
      :sum-text="sumText"
      :summary-method="summaryMethod"
      @span-method="spanMethod"
      :select-on-indeterminate="selectOnIndeterminate"
      :indent="indent"
      :lazy="lazy"
      :tree-props="treeProps"
      @load="load"
      @select="select"
      @select-all="selectAll"
      @selection-change="selectionChange"
      @cell-mouse-enter="cellMouseEnter"
      @cell-mouse-leave="cellMouseLeave"
      @cell-click="cellClick"
      @cell-dblclick="cellDblclick"
      @cell-contextmenu="cellContextmenu"
      @row-click="rowClick"
      @row-contextmenu="rowContextmenu"
      @row-dblclick="rowDblclick"
      @header-click="headerClick"
      @header-contextmenu="headerContextmenu"
      @sort-change="sortChange"
      @filter-change="filterChange"
      @current-change="currentChange"
      @header-dragend="headerDragend"
      @expand-change="expandChange"
      :class="[border ? '' : 'noBorder']"
  >
    <template v-slot:append v-if="$slots.append">
      <slot name="header"></slot>
    </template>
    <slot></slot>
  </el-table>
  <div class="lada-pagination" v-if="pagination" ref="ladaPagination">
    <el-pagination
        :page-size="pSize"
        :total="total"
        :page-sizes="[10, 20, 30, 50, 100, 200, 500]"
        :current-page="pPage"
        layout="total, jumper,next,prev, pager, sizes"
        @size-change="sizeChange"
        @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pSize: 10,
      pPage: 1,  //当前显示的页数
      setHeight: 0,
    }
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      }
    },
    height: [String, Number],
    maxHeight: [String, Number],
    defaultExpandAll: Boolean,
    style: [String, Array, Object],
    stripe: Boolean,
    border: {
      type: Boolean,
      default: false,
    },
    size: String,
    fit: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    highlightCurrentRow: Boolean,
    currentRowKey: [String, Number],
    rowClassName: String,
    rowStyle: Object,
    cellClassName: String,
    cellStyle: Object,
    headerRowClassName: {
      type: String,
      default: "lida-table-hader"
    },
    headerRowStyle: Object,
    headerCellClassName: String,
    headerCellStyle: Object,
    rowKey: {
      type: String,
      default: 'id',
    },
    emptyText: String,
    expandRowKeys: Array,
    defaultSort: Object,
    tooltipEffect: String,
    showSummary: {
      type: Boolean,
      default: false,
    },
    sumText: String,
    selectOnIndeterminate: {
      type: Boolean,
      default: true
    },
    indent: {
      type: Number,
      default: 16,
    },
    lazy: Boolean,
    treeProps: {
      type: Object,
      default: () => {
        return {hasChildren: 'hasChildren', children: 'children'}
      }
    },
    pagination: { //是否开启分页
      type: Boolean,
      default: true,
    },
    total: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    cutHeight: { //需要减掉的高度
      type: Number,
      default: 0,
    },
    summaryMethod: Function,
    page: { //当前页数
      type: Number,
      default: 1,
    },
    minHeight: { //表格的最小高度（默认300）
      type: Number,
      default: 300,
    }
  },
  methods: {
    showModel() {

    },
    // summaryMethod(columns,data){
    // 	this.$emit('summaryMethod',columns,data);
    // },
    spanMethod(row, column, rowIndex, columnIndex) {
      this.$emit('spanMethod', row, column, rowIndex, columnIndex);
    },
    load(row, treeNode, resolve) {
      this.$emit('load', row, treeNode, resolve);
    },
    select(selection, row) {
      this.$emit("select", selection, row);
    },
    selectAll(selection) {
      this.$emit('selectAll', selection);
    },
    selectionChange(selection, index) {
      this.$emit("selectionChange", selection);
    },
    cellMouseEnter(row, column, cell, event) {
      this.$emit('cellMouseEnter', row, column, cell, event);
    },
    cellMouseLeave(row, column, cell, event) {
      this.$emit('cellMouseLeave', row, column, cell, event);
    },
    cellClick(row, column, cell, event) {
      this.$emit('cellClick', row, column, cell, event);
    },
    cellDblclick(row, column, cell, event) {
      this.$emit('cellDblclick', row, column, cell, event);
    },
    cellContextmenu(row, column, cell, event) {
      this.$emit('cellContextmenu', row, column, cell, event);
    },
    rowClick(row, column, event) {
      this.$emit('rowClick', row, column, event);
    },
    rowContextmenu(row, column, event) {
      this.$emit('rowContextmenu', row, column, event);
    },
    rowDblclick(row, column, event) {
      this.$emit('rowDblclick', row, column, event);
    },
    headerClick(column, event) {
      this.$emit('headerClick', column, event);
    },
    headerContextmenu(column, event) {
      this.$emit('headerContextmenu', column, event);
    },
    sortChange(obj) {
      this.$emit("sortChange", obj);
    },
    filterChange(filters) {
      this.$emit('filterChange', filters);
    },
    currentChange(currentRow, oldCurrentRow) {
      this.$emit("currentChange", currentRow, oldCurrentRow);
    },
    headerDragend(newWidth, oldWidth, column, event) {
      this.$emit("headerDragend", newWidth, oldWidth, column, event)
    },
    expandChange(row, expandedRows) {
      this.$emit('expandChange', row, expandedRows);
    },
    getDom() { //获取当前的ref对象
      return this.$refs.lidaTable;
    },
    sizeChange(v) { //每页显示的数量改变
      this.pSize = v;
      this.$emit("change", this.pPage, v);
    },
    handleCurrentChange(v) {  //当前页数改变
      this.pPage = v;
      this.$emit("change", v, this.pSize);
    },
    getHeight() { //获取表格高度
      this.$nextTick(() => {
        var header = this.$refs.lidaTable.$refs.headerWrapper,  //头部元素
            dom = header.parentNode,
            top = dom.getBoundingClientRect().top;
        if (this.height) {
          // console.log(this.height);
          this.setHeight = this.height;  //判断如果没有设置table高度
        } else {
          this.setHeight = document.body.clientHeight - top - 30 - (
              this.pagination ? 54 : 0
          ) - this.cutHeight;   //自动设置table高度
          this.setHeight = this.setHeight < this.minHeight ? this.minHeight : this.setHeight;
        }
        //重新渲染(防止设置高度不生效)
        this.$forceUpdate()
      })
    }
  },
  computed: {},
  watch: {
    /** zhd 监听高度变化 */
    height(v) {
      this.setHeight = v
    },
    page(v) {
      this.pPage = v;
    },
  },
  created() {
    this.pSize = this.pageSize;
    this.pTotal = this.total;
    this.getHeight();
  }
}
</script>

<style scoped lang="less">
/deep/ .warning-row {
  background: #b1aeae;
}

/deep/ .el-table .success-row {
  background: #f0f9eb;
}

/deep/ .lida-table-hader {
  background: #f2f5fa;

  th.el-table__cell {
    background: #f2f5fa;
  }
}

/* 分页样式 */
.lada-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
}

.noBorder {
  /deep/ .lida-table-hader {
    .el-table__cell {
      .cell {
        border-right: 1px solid #747474;
      }
    }

    .el-table__cell:last-of-type {
      .cell {
        border-right: none;
      }
    }
  }

  /deep/ .el-table__body {
    td {
      border-right: none !important;
    }
  }
}
</style>
